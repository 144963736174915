import { LOCALES } from '../constants';

export default {
  [LOCALES.ITALIAN]: {
    'by_completing': 'Partecipando a questo sondaggio acconsentite all’utilizzo, da parte dell’Austin Health, delle informazioni da voi fornite allo scopo di migliorare la qualità dei nostri servizi',
    'with thanks': 'Vi ringraziamo',
    'austin': 'Austin Health',
    'maximum': 'Non piú di 500 caratteri',
    'comments': 'Altri commenti?',
    'survey_type': 'Sondaggio sull’Esperienza del Paziente',
    'following_your':'Dopo la vostra recente visita all’ Austin Health, per favore completate il nostro sondaggio anonimo di 30 secondi all’indirizzo: ',
    'thinking_about':'Considerando il vostro recente ricovero nel reparto di {path}, per favore completate questo sondaggio sulla vostra esperienza',
    'overall':'Nel complesso, quanto siete soddisfatti dell’assistenza e del trattamento?',
    'very_satisfied':'Molto soddisfatti',
    'very_dissatisfied':'Molto insoddisfatti',
    'how_likely':'Quanto é probabile che raccomandiate l’Austin Health a un amico oppure a un collega?',
    'not_at_all':'Per niente probabile',
    'very_likely':'Molto probabile',
    'any_comments':'Altri commenti? Non piú di 500 caratteri',
    'submit_survey':'Inviate il sondaggio',
    'thank_you':'Grazie per la compilazione del nostro Sondaggio sull’Esperienza del Paziente',
    'your_response':'Le vostre risposte verranno usate allo scopo di migliorare la qualitá.',
    'austin_health':'L’Austin Health ha un processo per gestire i commenti formali, per favore fate riferimento al sito web dell’Austin Health ',
    'mental_health':'Se state vivendo una crisi di salute mentale e avete bisogno di supporto, chiamate il Austin Mental Health Triage al 1300 859 789.',

  },
};