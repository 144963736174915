import en from './en-US';
import ch from './ch-CH';
import ar from './ar-AR';
import cr from './cr-CR';
import gr from './gr-GR';
import vt from './vt-VT';
import sch from './sch-SCH';
import it from './it-IT';
import sr from './sr-SR';
import ma from './ma-MA';

export default {
  ...en,
  ...ch,
  ...ar,
  ...cr,
  ...gr,
  ...vt,
  ...it,
  ...ma,
  ...sr,
  ...sch,
};