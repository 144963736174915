import { LOCALES } from '../constants';

export default {
  [LOCALES.SCHINESE]: {
    'by_completing': '完成此问卷即代表您同意Austin Health使用您提供的信息作改善服务素质之用',
    'with thanks': '谢谢',
    'austin': 'Austin Health',
    'maximum': '不超过500字',
    'comments': '其他意见？',
    'survey_type': '病人体验问卷',
    'following_your':'请根据最近在Austin医院的经验，填写需时30秒的匿名问卷调查：',
    'thinking_about':'请根据最近在{path}的住院经验完成问卷调查',
    'overall':'总体来说，您对护理和治疗是否满意？',
    'very_satisfied':'非常满意',
    'very_dissatisfied':'非常不满意',
    'how_likely':'您会向朋友或同事推荐Austin医院吗？',
    'not_at_all':'不太可能',
    'very_likely':'很有可能',
    'any_comments':'其他意见？不超过500字',
    'any_comments_label':'',
    'submit_survey':'提交问卷调查',
    'thank_you':'感谢您完成本院的病人体验问卷调查',
    'your_response':'您的反馈将帮助提高服务质量。',
    'austin_health':'Austin医院有处理反馈的流程，请访问Austin医院的网页',
    'mental_health':'若您正面对精神健康危机并需要援助，请拨打1300 859 789联系Austin Mental Health Triage。',
  },
};