export const msalConfig = {
  auth: {
    clientId: `9246c67c-512f-46a5-b5db-314fd9d13171`,
    authority: `https://login.microsoftonline.com/680ffb06-000e-46a1-a455-ab68252c398d`,
    postLogoutRedirectUri: ``,
    redirectUri: process.env.REDIRECT_URI
  }
}
const msGraphUsers = {
  graphMeEndpoint:  "https://graph.microsoft.com/v1.0/me",
  graphAdminGroupEndpoint: "https://graph.microsoft.com/v1.0/groups/0acbab24-256f-46d7-bb5e-5fb8540e97f4/members"
};
export const loginRequest = {
  scopes: ["User.Read","Group.ReadWrite.All","Directory.ReadWrite.All"]
}

export async function callMeMsGraph(
  accessToken,
  endpoint = msGraphUsers.graphMeEndpoint,
  method = "GET"
) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("content-type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };
  if (method.toUpperCase() !== "GET") {
    options.body = JSON.stringify({ securityEnabledOnly: true });
  }

  return fetch(endpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function callAdminMsGraph(
  accessToken,
  endpoint = msGraphUsers.graphAdminGroupEndpoint,
  method = "GET"
) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("content-type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };
  if (method.toUpperCase() !== "GET") {
    options.body = JSON.stringify({ securityEnabledOnly: true });
  }

  return fetch(endpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
