import { LOCALES } from '../constants';

export default {
  [LOCALES.GREEK]: {
    'by_completing': 'Με την ολοκλήρωση αυτής της έρευνας δίνετε την συγκατάθεση σας  στην  Austin Health να χρησιμοποιήσει τις πληροφορίες που παρέχετε για σκοπούς ποιοτικής βελτίωσης',
    'with thanks': 'Ευχαριστούμε ',
    'austin': 'Austin Health',
    'maximum': 'Μέγιστο 500 χαρακτήρες',
    'comments': 'Κάποια άλλα σχόλια;',
    'survey_type': '‘Ερευνα Εμπειρίας Ασθενών',
    'following_your':'Μετά την πρόσφατη διαμονή σας στο  Austin Health, παρακαλούμε συμπληρώστε την ανώνυμη έρευνά  μας των 30 δευτερολέπτων: ',
    //'thinking_about':'Σκεφτόμενος την πρόσφατη εισαγωγή σας στο {path} παρακαλούμε συμπληρώστε αυτή την έρευνα σχετικά με την εμπερία σας.',
    'thinking_about':'Σκεφτόμενος την πρόσφατη εισαγωγή σας στο {path} παρακαλούμε συμπληρώστε αυτή την έρευνα σχετικά με την εμπειρία σας.',
    'overall':'Γενικά, πόσο ικανοποιημένος ήσασταν με τη φροντίδα και τη θεραπεία σας;',
    'very_satisfied':'Πολύ ικανοποιημένος',
    'very_dissatisfied':'Πολύ δυσαρεστημένος',
    'how_likely':'Πόσο πιθανόν είναι να προτείνετε το Austin Health σε φίλο ή συνάδελφο;',
    'not_at_all':'Όχι πολύ πιθανό',
    'very_likely':'Πολύ πιθανό',
    'any_comments':'Κάποια άλλα σχόλια; Μέγιστο 500 χαρακτήρες',
    'any_comments_label':'',
    //'submit_survey':'Υποβολή έρευνας',
    'submit_survey':'ΥΠΟΒΟΛΗ ΕΡΕΥΝΑΣ',
    'thank_you':'Σας ευχαριστούμε που συμπληρώσατε την Έρευνά μας Εμπειρίας Ασθενών',
    'your_response':'Οι απαντήσεις σας θα χρησιμοποιηθούν για σκοπούς βελτίωσης της ποιότητας',
    'austin_health':'Το Austin Health έχει μια διαδικασία για τη διαχείριση επίσημων σχολίων,  παρακαλούμε ανατρέξετε στον ιστότοπο του Austin Health website ',
    'mental_health':'Εάν αντιμετωπίζετε κρίση ψυχικής υγείας και χρειάζεστε υποστήριξη, καλέστε το Austin Mental Health Triage στο 1300 859 789. ',
  },
};