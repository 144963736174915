import { LOCALES } from '../constants';

export default {
  [LOCALES.MACEDONIAN]: {
    'by_completing': 'Со пополнувањето на оваа анкета вие давате согласност Austin Health да ги користи информациите што ги дадовте за цели на подобрување на квалитетот',
    'with thanks': 'Благодариме',
    'austin': 'Austin Health',
    'maximum': 'Најмногу 500 знаци',
    'comments': 'Имате ли други коментари?',
    'survey_type': 'Анкета за искуствата на пациентите',
    'following_your':'После вашиот неодамнешен престој во Austin Health, ве молиме одвојте 30 секунди за да ја пополните нашата анонимна анкета:',
    //'thinking_about':'Во врска со вашиот неодамнешен прием како пациент во {path}, ве молиме пополнете ја оваа анкета ѕа вашите искуства',
    'thinking_about':'Во врска со вашиот неодамнешен прием како пациент во {path}, ве молиме пополнете ја оваа анкета за вашите искуства',
    'overall':'Општо земено, колку бевте задоволни со вашата нега и лекување?',
    'very_satisfied':'Многу задоволен/задоволна',
    'very_dissatisfied':'Многу незадоволен/незадоволна',
    'how_likely':'Колку е веројатно дека ќе ја препорачате Austin Health на пријател или колега?',
    'not_at_all':'Воопшто не е веројатно',
    'very_likely':'Многу веројатно',
    'any_comments':'Имате ли други коментари? Најмногу 500 знаци',
    'submit_survey':'Пратете назад',
    'thank_you':'Благодариме што ја пополнивте нашата анкета за искуството на пациентите.',
    'your_response':'Вашите одговори ќе се користат за подобрување на квалитетот.',
    'austin_health':'Austin Health има процес за обработка на формални повратни информации; ве молиме видете на интернет страницата на Austin Health ',
    'mental_health':'Ако преживувате ментална криза и ви треба поддршка, ве молиме јавете се на Austin Mental Health Triage на 1300 859 789.',

  },
};