import { LOCALES } from '../constants';

export default {
  [LOCALES.CHINESE]: {
    'by_completing': '完成此問卷即代表您同意Austin Health使用您提供的資料作改善服務質素之用',
    'with thanks': '謝謝',
    'austin': 'Austin Health',
    'maximum': '不超過500字',
    'comments': '其他意見？',
    'survey_type': '病人體驗問卷調查',
    'following_your':'請根據最近在Austin醫院的經驗，填寫需時30秒的匿名問卷調查：',
    'thinking_about':'請根據最近在{path}的住院經驗完成問卷調查',
    'overall':'總體來說，您對護理和治療是否滿意？ ',
    'very_satisfied':'非常滿意',
    'very_dissatisfied':'非常不滿意',
    'how_likely':'您會向朋友或同事推薦Austin醫院嗎？ ',
    'not_at_all':'不太可能',
    'very_likely':'很有可能',
    'any_comments':'其他意見？ 不超過500字',
    'any_comments_label':'',
    'submit_survey':'提交問卷調查',
    'thank_you':'感謝您完成本院的病人體驗問卷調查',
    'your_response':'您的反饋將幫助提高服務質量。',
    'austin_health':'Austin醫院有處理反饋的流程，請訪問Austin醫院的網頁',
    'mental_health':'若您正面對精神健康危機並需要援助，請致電1300 859 789聯絡Austin Mental Health Triage。',
  },
};