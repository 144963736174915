import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH]: {
    'by_completing': 'By completing this survey you are consenting to Austin Health using the information you provide for quality improvement purposes',
    'with thanks': 'With thanks',
    'austin': 'Austin Health',
    'maximum': 'Max 500 characters',
    'comments': 'Any other comments?',
    'survey_type': 'Patient Experience Survey..',
    'following_your':'Following your recent stay at Austin Health, please complete our anonymous 30 second survey :',
    'thinking_about':'Thinking about your recent inpatient admission in {path}, please complete this survey regarding your experience',
    'overall':'Overall, how satisfied were you with your care and treatment?',
    'very_satisfied':'Very satisfied',
    'very_dissatisfied':'Very disatisfied',
    'how_likely':'How likely are you to recommend Austin Health to a friend or colleague?',
    'not_at_all':'Not at all likely',
    'very_likely':'Very likely',
    'any_comments':'Any other comments? Max 500 characters',
    'submit_survey':'Submit survey',
    'thank_you':'Thank you for completing our Patient Experience Survey',
    'your_response':'Your responses will be used for quality improvement purposes.',
    'austin_health':'Austin Health has a process for managing formal feedback, please refer to the Austin Health website ',
    'mental_health':'If you are experiencing a mental health crisis and require support please call Austin Mental Health Triage on 1300 859 789.',

  },
};