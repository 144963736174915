
import { LOCALES } from '../constants';

export default {
  [LOCALES.CROATIAN]: {
    'by_completing': 'Popunjavanjem ove ankete vi dajete suglasnost da Austin Health koristi informacije koje ste dali za ciljeve poboljšanja kvalitete',
    'with thanks': 'Zahvaljujemo',
    'austin': 'Austin Health',
    'maximum': 'Maksimum 500 znakova',
    'comments': 'Imate li drugih komentara?',
    'survey_type': 'Anketa o iskustvima pacijenata',
    'following_your':'Nakon vašeg nedavnog boravka u Austin Health-u, molimo vas odvojte 30 sekundi da popunite našu anonimnu anketu:',
    'thinking_about':'U vezi vašeg nedavnog prijema kao pacijenta u {path}, molimo vas popunite ovu anketu o vašim iskustvima',
    'overall':'Opće uzevši, koliko ste bili zadovoljni vašom negom i liječenjem?',
    'very_satisfied':'Veoma zadovoljan/zadovoljna',
    'very_dissatisfied':'Veoma nezadovoljan/nezadovoljna',
    'how_likely':'Koliko je vjerojatno da ćete Austin Health preporučiti prijatelju ili kolegi?',
    'not_at_all':'Uopće nije vjerojatno',
    'very_likely':'Vrlo vjerojatno',
    'any_comments':'Imate li drugih komentara? Maksimum 500 znakova',
    'any_comments_label':'Imate li drugih komentara',
    'submit_survey':'Pošaljite natrag',
    'thank_you':'Hvala vam što ste popunili našu anketu o iskustvima pacijenata.',
    //'your_response':'Važi odgovori će se koristiti za poboljšanje kvalitete.',
    'your_response':'Vaši odgovori će se koristiti za poboljšanje kvalitete.',
    'austin_health':'Austin Health ima proces za obradu povratnih informacija; molimo vas pogledajte na internet stranici Austin Health-а ',
    'mental_health':'Ako preživljujete mentalnu krizu i treba vam podrška, molimo vas nazovite Austin Mental Health Triage na 1300 859 789. ',
  },
};