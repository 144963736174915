import { LOCALES } from '../constants';

export default {
  [LOCALES.SERBIAN]: {
    //'by_completing': 'Испуљавањем ове анкете ви дајете сагласност Austin Health-у да користи информације које сте дали за циљеве побољшања квалитета',
    'by_completing': 'Испуњавањем ове анкете ви дајете сагласност Austin Health-у да користи информације које сте дали за циљеве побољшања квалитета',
    'with thanks': 'Захваљујемо',
    'austin': 'Austin Health',
    'maximum': 'Максимум 500 знакова',
    'comments': 'Имате ли других коментара?',
    'survey_type': 'Анкета о искуствима пацијената',
    'following_your':'После вашег недавног боравка у Austin Health-у, молимо вас да одвојите 30 секунди да попуните нашу анонимну анкету: ',
    'thinking_about':'У вези вашег недавног пријема као пацијента у {path}, молимо вас да попуните ову анкету о вашим искуствима',
    'overall':'Опште узето, колико сте били задовољни са вашом негом и лечењем?',
    'very_satisfied':'Врло задовољан/задовољна',
    'very_dissatisfied':'Врло незадовољан/незадовољна',
    'how_likely':'Колико је вероватно да ћете препоручити Austin Health пријатељу или колеги?',
    'not_at_all':'Уопште није вероватно',
    'very_likely':'Врло вероватно',
    'any_comments':'Имате ли других коментара? Максимум 500 знакова',
    'submit_survey':'Пошаљите натраг',
    'thank_you':'Хвала што сте попунили нашу анкету о искуствима пацијената.',
    'your_response':'Ваши одговори ће се користити за побољшање квалитета.',
    'austin_health':'Austin Health има процес за обраду формалних повратних информација; молимо вас видите на интернет страници Austin Health-а ',
    //'mental_health':'Ako preživljujete mentalnu krizu i treba vam podrška, molimo vas nazovite Austin Mental Health Triage na 1300 859 789. ',
    'mental_health':'Ако преживљавате менталну кризу и треба вам подршка, молимо вас назовите Austin Mental Health Triage на 1300 859 789.',
  },
};