import React, {useLayoutEffect} from 'react'
import {Typography,createMuiTheme, StylesProvider, jssPreset, ThemeProvider} from '@material-ui/core'
import {create} from 'jss'
import rtl from 'jss-rtl'
import { I18nPropvider } from '../i18nProvider/index';
import translate from "../i18nProvider/translate";

function ThankYou({typeId,locale,mhStatus}) {
    useLayoutEffect(() => {
        document.body.setAttribute("dir", locale=='ar' ? "rtl" : "ltr");

      }, [])

      const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
      const ltrTheme = createMuiTheme({ direction: "ltr" });
      const rtlTheme = createMuiTheme({ direction: "rtl" });
      console.log(mhStatus)
    return (
        
        <I18nPropvider locale={locale}>
        <StylesProvider jss={jss}>
        <ThemeProvider theme={locale=='ar' ? rtlTheme : ltrTheme}>
        <div>
            <Typography style={{margin: '15px'}} variant='h6' align='left'>
             {typeId == 2 ? `Thank you for completing our Discharge summary Survey` : translate('thank_you')}  <br/>
            <br/>
            {translate('your_response')} <br/>
            <br/>
            {(typeId == 1 || !typeId) && locale=='ar'?
                <>
                {translate('austin_health')}  <a href="https://www.austin.org.au/feedback/" target="_blank" rel="noopener noreferrer">https://www.austin.org.au/feedback</a> <br/>
                    <br/>
                </> :
                undefined
            }
            {(typeId == 1 || !typeId) && locale !='ar'?
                <>
                {translate('austin_health')}  <a href="https://www.austin.org.au/feedback/" target="_blank" rel="noopener noreferrer">https://www.austin.org.au/feedback/</a> <br/>
                    <br/>
                </> :
                undefined
            }
            {mhStatus?<> {translate('mental_health')} <br/> <br/></>: undefined}
            {translate('with thanks')} <br/>
            <br/>
            {typeId == 1 ? `The Patient Experience Unit` : translate('austin')}

            </Typography>
        </div>
        </ThemeProvider>
        </StylesProvider>
        </I18nPropvider>
    )
}

export default ThankYou