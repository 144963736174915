import { LOCALES } from '../constants';

export default {
  [LOCALES.VIETNAMESE]: {
    'by_completing': 'Bằng việc hoàn thành cuộc khảo sát này, quý vị đồng ý cho bệnh viện Austin sử dụng thông tin quý vị cung cấp cho các mục đích cải tiến chất lượng',
    'with thanks': 'Xin cảm ơn',
    'austin': 'Austin Health',
    'maximum': 'Tối đa 500 ký tự',
    'comments': 'Bất kỳ ý kiến nào khác?',
    'survey_type': 'Khảo sát Trải nghiệm của Bệnh nhân',
    'following_your':'Sau lần nằm viện gần đây của bạn tại Austin Health, vui lòng hoàn thành khảo sát ẩn danh kéo dài 30 giây của chúng tôi: ',
    'thinking_about':'Nghĩ về lần nhập viện nội trú gần đây của bạn ở {path}, vui lòng hoàn thành bản khảo sát này về trải nghiệm của mình',
    'overall':'Nhìn chung, bạn hài lòng đến mức nào với dịch vụ chăm sóc và điều trị cho mình?',
    'very_satisfied':'Rất hài lòng',
    'very_dissatisfied':'Rất không hài lòng',
    'how_likely':'Khả năng bạn sẽ giới thiệu Austin Health cho bạn bè hoặc đồng nghiệp là như thế nào?',
    'not_at_all':'Hoàn toàn không',
    'very_likely':'Rất có thể',
    'any_comments':'Bất kỳ ý kiến nào khác? Tối đa 500 ký tự',
    'any_comments_label':'',
    'submit_survey':'Nộp bản khảo sát',
    'thank_you':'Cảm ơn bạn đã hoàn thành Khảo sát Trải nghiệm của Bệnh nhân của chúng tôi',
    'your_response':'Các câu trả lời của bạn sẽ được sử dụng cho mục đích cải tiến chất lượng.',
    'austin_health':'Austin Health có quy trình quản lý ý kiến phản hồi chính thức, vui lòng tham khảo trên trang mạng của Austin Health ',
    'mental_health':'Nếu bạn đang bị khủng hoảng tinh thần và cần hỗ trợ, xin vui lòng gọi cho Austin Mental Health Triage theo số 1300 859 789.',
  },
};
