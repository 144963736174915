export const LOCALES = {
    // ENGLISH: 'en-us',
    // CHINESE: 'ch-CH',
    // CROATIAN: 'cr-CR',
    // ARABIC: 'ar-AR',
    // GREEK: 'gr-GR',
    // SCHINESE: 'sch-SCH',
    // VIETNAMESE: 'vt-VT',
    ENGLISH: 'en',
    CHINESE: 'ch',
    CROATIAN: 'cr',
    ARABIC: 'ar',
    GREEK: 'gr',
    SCHINESE: 'sch',
    VIETNAMESE: 'vt',
    SERBIAN: 'sr',
    ITALIAN: 'it',
    MACEDONIAN: 'ma',
  };

