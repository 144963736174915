import { React, useEffect, useState} from "react";
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest,callAdminMsGraph,callMeMsGraph } from "../features/auth/authConfig";
import { ErrorComponent } from "../features/common/ErrorComponent";
import { Loading } from "../features/common/Loading";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Button,
  MenuItem,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { GET_COMMENTS, UPDATE_HIDDEN } from "./common/Queries";
import ClearIcon from "@material-ui/icons/Clear";

function Admin() {
  const surveyUrl = 'https://ah-pes-backend.azurewebsites.net/graphql'
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});
  const [rows, setRows] = useState([]);
  const [searchVal, setSearchVal] = useState('')
  const [isDisplayed, setIsDisplayed] = useState(false);  
  const [dateVal, setDateVal] = useState('')
  const [wardVal, setWardVal] = useState('')
  const [filteredComments, setFilteredComments] = useState([]);  
  const authRequest = {
    ...loginRequest,
  };
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const tokenRefreshInterval = 3300000;
  useEffect(async() => {
    if (account && inProgress === "none") {
       await instance.acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then(async(response) => {
          //console.log(response)
          const meProfileRes = await callMeMsGraph(response.accessToken);
          const adminProfileRes = await callAdminMsGraph(response.accessToken);
          setToken(response?.idToken);
          const isAdmin = adminProfileRes.value.map(it=>it.id).includes(meProfileRes.id)
          setUser(isAdmin);
          console.log("Is Admin? ",isAdmin)
          isAdmin && loadComments()
          setInterval(() => {
                  setIsDisplayed(true)
                }, 500);
         //console.log(result.data.getcomments)
        });
    }
    
    //Logic to renew token every 55 min. Expiration of token is 60 min
    const interval = setInterval(() => {
      console.log("token refresh time", new Date());
      if (account && inProgress === "none") {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: account,
          })
          .then((response) => {
           // console.log(response);
            setToken(response?.idToken);
            setUser(response);
          });
      }
    }, tokenRefreshInterval);
    return () => clearInterval(interval);

  }, [account, inProgress, instance]);

const loadComments = async() => {
        return await fetch(surveyUrl, {
            headers: {
                'Content-type': 'application/json',
                'Allow-Cross-Remote-Origin': '*'
                //'authentication': accessToken
            },
            method: 'POST',
            body: JSON.stringify({
                query: ( GET_COMMENTS() )
            })
        })
            .then(res => res.json())
            .then(data => {
              setRows(data.data.getComments)
              setFilteredComments(data.data.getComments)
                console.log((data.data.getComments))
                if (data?.errors?.length > 0) throw new Error(data?.errors[0].message) 
            })
    }

    const updateComment = async(id,hidden) => {
      console.log(id,hidden)
      return await fetch(surveyUrl, {
          headers: {
              'Content-type': 'application/json',
              'Allow-Cross-Remote-Origin': '*'
              //'authentication': accessToken
          },
          method: 'POST',
          body: JSON.stringify({
              query: (UPDATE_HIDDEN(id,parseInt(hidden)))
          })
      })
          .then(res =>  res.json()
          )
          .then(data => {
              //console.log(data.data.updateHidden)
              if (data.data.updateHidden !== 1) throw new Error("No row updated") 
          })
  }

  //STYLES
  const useStyles = makeStyles((theme) => ({
    select: {
      height: 35,
      // marginBottom: '100px',
      // minWidth: '100%',
      // maxWidth: 1000,
      // display: 'block',
      position: "unset",
    },
    fillWidth: {
      minWidth: "100%",
      maxWidth: 1000,
    },
    paper: {
      alignItems: "center",
      marginLeft: "10%",
      marginRight: "10%",
      width: '80%',
    },
    mobilePaper: {
      alignItems: "center",
      marginLeft: "10px",
      marginRight: "10px",
      width: "100%",
    },
    cancelButton: {
      color: "red",
      backgroundColor: "white",
      outlineWidth: 0,
      alignItems: "right",
      // display: 'flex',
      verticalAlign: "center",
    },
    submitButton: {
      marginLeft: "35px",
      marginRight: "35px",
      width: "97.8%",
    },
    mobileSubmitButton: {
      marginLeft: "10px",
      marginRight: "10px",
      width: "100%",
    },
    ratingCard: {
      margin: "15px",
      textAlign: "center",
    },
    formMain: {
      height: "89.8vh",
      marginLeft: "20vw",
      marginRight: "24.5vw",
      display: "flex",
      backgroundColor: "#dfdfdf",
      // overflow-y: auto;
      // overflow-x: hidden;
      minWidth: "fitContent",
    },
    formItem: {
      marginLeft: "20px",
      marginRight: "20px",
      marginTop: "10px",
      marginBottom: "10px",
    },
    formList: {
      marginTop: "2px",
      width: "95.5%",
      textAlign: "left",
      // display: 'flex'
    },
  }));

  const classes = useStyles()

  // const getHidden = (params) => {
  //   const checked = params.row.hidden == '1'
  //   return checked;
  // }

  const getVisibleText = (params) => {
    const checked = params.row.hidden == '1'
    return checked?"No":"Yes";
  };

  const columns = [
    
    {
      field: "dischargeDate",
      headerName: "Discharge Date",
      //type: "datetime",
      width: 150,
      disableColumnMenu:true
    },
    { field: "unitCode", headerName: "Unit Code", width: 90,disableColumnMenu:true },
    { field: "unitDesc", headerName: "Unit Desc", width: 170 ,disableColumnMenu:true, renderCell: (params) => {
      if(params?.row?.unitDesc){
      return ( 
      <Tooltip title={params?.row?.unitDesc}>
        <span>{params?.row?.unitDesc}</span>
      </Tooltip>)}}},
    {
      field: "ward",
      headerName: "Ward",
      //type: "datetime",
      flex: 1,
      disableColumnMenu:true,
      renderCell: (params) => {
        if(params?.row?.ward){
        return ( 
        <Tooltip title={params?.row?.ward}>
          <span>{params?.row?.ward}</span>
        </Tooltip>)}}},
    {
      field: "comments",
      headerName: "Comments",
      //type: "datetime",
      flex: 1,
      disableColumnMenu:true,
      renderCell: (params) => {
        if(params?.row?.comments){
        return ( 
        <Tooltip title={params?.row?.comments}>
          <span>{params?.row?.comments}</span>
        </Tooltip>)}}},
    
    {
      field: 'visible',
      headerName: 'Visible',
      valueGetter:getVisibleText,
      disableColumnMenu:true,
      width: 100,
    },
    
    {
      field: "action",
      headerName: "Action",
      width: 150,
      type: "boolean",
      disableColumnMenu:true,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={()=>{handleButtonClick(params.row.id,params.row.hidden)}}
          >
          {params.row.hidden==1?"Unhide":"Hide"}
          </Button>
        </strong>
      ),
     
    },
    
  ];
  // const handleSubmit = (e) => {
  //   updateComment(e.row.id, e.row.hidden?0:1)
  //   console.log(e.row.id, e.row.hidden)
  // };
  const handleButtonClick = async(id,hidden) => {
    console.log(id,hidden)
    console.log(id,hidden=='1'?0:1)
    
    await updateComment(id, hidden=='1'?0:1)
    await loadComments()
    
  }
  let newList = []
  const handleSearch = (e)=>{
    setSearchVal(e.target.value)
    setWardVal('')
    setDateVal('')
    const filterSearch=rows?.filter(x=>(x.comments?.toUpperCase().includes(e.target.value?.toUpperCase())) 
    || x.dischargeDate?.toUpperCase().includes(e.target.value?.toUpperCase())
    || x.unitCode?.toUpperCase().includes(e.target.value?.toUpperCase())
    || x.unitDesc?.toUpperCase().includes(e.target.value?.toUpperCase())
    || x.ward?.toUpperCase().includes(e.target.value?.toUpperCase())
    )
    console.log(filterSearch)
    //setAllGifts(filterSearch)
    setFilteredComments(filterSearch)
  }
  const handleWardSearch = (e)=>{
    setDateVal('')
    setSearchVal('')
    setWardVal(e.target.value)
    const filterSearch=rows?.filter(x=>x.ward?.toUpperCase().includes(e.target.value?.toUpperCase())
    )
    setFilteredComments(filterSearch)
  }
  const handleDateSearch = (e)=>{
    setSearchVal('')
    setWardVal('')
    if(e.target.value){
    setDateVal(e.target.value)
    //const filterSearch = rows?.filter(task => Date.parse(task?.dischargeDate?.split('-').reverse().join('-'))==Date.parse(e.target.value)) 
    const filterSearch = rows?.filter(task => {
        var dd = String(task?.dischargeDate?.split('-')?.[0].padStart(2, '0'));
        var mm = String(task?.dischargeDate?.split('-')?.[1].padStart(2, '0'));
        var yyyy = String(task?.dischargeDate?.split('-')?.[2])
        const newDate = yyyy+'-'+mm+'-'+dd
        if(newDate===e.target.value) 
        {return task}

    })
    setFilteredComments(filterSearch)}
    else{
      setFilteredComments(rows)}
    }
  

  return (
    <div>
    { !user ? <Typography variant="h6" style={{ textAlign: "left" }}>
    <b>You are not authorised to access this page</b>
    </Typography>:
    <div>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.formItem}>
          <Typography variant="h6" style={{ textAlign: "left" }}>
            <b>Admin Page</b>
          </Typography>
        </div>
      </Paper>

      <Paper
        className={classes.paper}
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
        elevation={3}
      >
        <div className={classes.formItem}>
          <Typography variant="body2" style={{ textAlign: "left" }}>
            Hide or show comments:
          </Typography>
          <div style={{ display: 'flex', justifyContent:'flex-end' }}>
          <TextField name="toDate" label="- Search by Date" type='date' style = {{width:250, marginRight:100}} InputLabelProps={{shrink:true}} onChange = {handleDateSearch} value={dateVal} InputProps={{
            endAdornment: (
              <IconButton onClick={() => {setDateVal('')
              setFilteredComments(rows)}}>
                  <ClearIcon />
                </IconButton>
            )
          }}/>
          <TextField id="searchWard" label="Search Ward" select= {true} variant="standard" style={{ width: 250, marginRight:100}} onChange = {handleWardSearch} value={wardVal} InputProps={{
            startAdornment: (
              <IconButton  onClick={() => {setWardVal('')
              setFilteredComments(rows)}}>
                  <ClearIcon position="start"/>
                </IconButton>
            )
          }}>
          {
            Object.values(rows).map((item,pos)=>{
            if(item.ward){
              if(newList.indexOf(item.ward) === -1){
                newList.push(item.ward)
                return(
                  <MenuItem key={pos} value={item.ward}>
                      {item.ward}
                  </MenuItem>) }
                }
              })
              } 
      
          </TextField>
          <TextField id="search" label="Search" variant="standard" onChange={handleSearch} value={searchVal} style={{ width: 250, marginRight:20}} InputProps={{
            endAdornment: (
                <IconButton onClick={() => {setSearchVal('')
                setFilteredComments(rows)}}>
                  <ClearIcon />
                </IconButton>
            )
          }}/>
          </div>
          <div style={{ height: 850, width: "100%",  marginTop:30 }}>
          {isDisplayed &&
          <DataGrid
          getRowId={(row) => row.id}
              rows={filteredComments}
              columns={columns}
              //onCellEditCommit={handleSubmit}
              // pageSize={5}
              rowsPerPageOptions={[50, 100, 150, 200, 1000]}
              disableSelectionOnClick
            />}
          </div>
          
        </div>
      </Paper>
    </MsalAuthenticationTemplate>
    <UnauthenticatedTemplate>
    <Typography variant="h6" style={{ textAlign: "left" }}>
    <b>You are not authorised to access this page</b>
  </Typography>
    </UnauthenticatedTemplate>
    </div>
  }
  </div>
  );
}

export default Admin;
