import { LOCALES } from '../constants';

export default {
  [LOCALES.ARABIC]: {
    'by_completing': 'إنك من خلال تعبِئة هذا الاستطلاع تُصرِّح لمستشفى أوستِن هيلث باستعمال المعلومات التي تُدلي بها من أجل أهداف تحسين الجودة',
    'with thanks': 'مع الشكر',
    'austin': 'مستشفى أوستِن هيلث',
    'maximum': '٥٠٠ حرف كحد أقصى',
    'comments': 'هل لديك تعليقات أو ملاحظات أخرى؟',
    //'survey_type': 'استطلاع حول تجربة المريض',
      'survey_type': 'استطلاع حول تجربة المريض',
    'following_your':':بعد اقامتك مؤخرًا في مستشفى أوستين، الرجاء الإجابة علىاستطلاعنا مجهول الهوية والسريع الذي يستغرق ٣٠ ثانية',
    'thinking_about':'فكِّر باقامتك مؤخرًا كمريض داخلي في {path} الرجاء الإجابة على استطلاع الرأي هذا بخصوص تجربتك',
    'overall':'عمومًا، ما مدى رضاك عن العناية بك والعلاج الذي تلقيته؟',
    'very_satisfied':'راضٍ جدًا',
    'very_dissatisfied':'مستاء جدًا',
    'how_likely':'ما مدى احتمال أن توصي صديق لك أو زميل باللجوء إلى خدماتنا في مستشفى أوستين؟ ',
    'not_at_all':'من غير المُحتمل إطلاقًا',
    'very_likely':'من المحتمل جدًا',
    'any_comments':'هل لديك تعليقات أو ملاحظات أخرى؟ [٥٠٠ حرف كحد أقصى]',
    'any_comments_label':'',
    'submit_survey':'تقديم الاستطلاع',
    //'thank_you':'شكرًا لقيامك بإتمام استطلاع رضى المرضى لدينا',
    'thank_you':'شكرًا لقيامك بإتمام استطلاع رضى المرضى لدينا',
    //'your_response':'.ستُستعمل ردودك لغرض تحسين الجودة',
     'your_response':'ستُستعمل ردودك لغرض تحسين الجودة.',
    'austin_health':'يوجد لدى مستشفى أوستين إجراء للتعامُل مع الملاحظات والتعليقات الرسمية، الرجاء مراجعة موقع مستشفى أوستين الآتي:',
    //'mental_health':'إذا كنت تعاني من أزمة صحة عقلية أو نفسية وتحتاج للمساعدة الرجاء التصال بقسم فرز واستقبال مرضى الصحة العقلية في مستشفى أوس تن على الرقم 789 859 1300.',
    //'mental_health':'إذا كنت تعاني من أزمة صحة عقلية أو نفسية وتحتاج للمساعدة الرجاء الاتصال بقسم فرز واستقبال مرضى الصحة العقلية في مستشفى أوستِن على الرقم789 859 1300 .',
     'mental_health':'إذا كنت تعاني من أزمة صحة عقلية أو نفسية وتحتاج للمساعدة الرجاء الاتصال بقسم فرز واستقبال مرضى الصحة العقلية في مستشفى أوستِن على الرقم 789 859 1300.',
    
  },
};
